import React from 'react';
import ConfigurationProvider from '../providers/ConfigurationProvider';
import Helmet from '../../Helmet';
import { NotFoundPage } from '../components';

export default () => (
  <ConfigurationProvider>
    <Helmet title='Not Found' />
    <NotFoundPage />
  </ConfigurationProvider>
);
